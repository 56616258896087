@import '../../styles/variables.scss';

.childrenSelection {
  .title {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    margin-top: 40px;
    max-width: 250px;
    color: $primary900;
  }

  .bottom {
    background-color: $primary50;
    width: 100%;
    padding-top: 40px !important;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 40px;
      background: linear-gradient(180deg, myRgba($primary, 15%) 0%, rgba(0, 0, 0, 0) 100%);
      z-index: 1;
    }
  }
}

.maxChildren {
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  color: $primary900;
  margin-bottom: 10px;
}
