.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99990000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  .popup {
    width: 90%;
    max-width: 500px;
    background-color: #fff;
    padding: 20px;
    padding-top: 40px;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
