@import '../../styles/variables.scss';

.addNewButton {
  button {
    width: 100px;
    height: 100px;
    border-radius: 20px;
    background-color: $primary100;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &.blocked {
      border-radius: 20px;
      overflow: hidden;
      .offlineOverlay {
        border-radius: 20px;
        backdrop-filter: brightness(0.8) saturate(0.4);
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;

        > img {
          width: max(32px, 50%);
          height: max(32px, 50%);
          margin: auto;
        }
      }
    }
  }

  .text {
    font-size: 15px;
    font-weight: 500;
    color: $primary900;
  }
}
