@import '../../styles/variables.scss';

.isUnique {
  font-size: 14px;
  color: #797979;
}

.altusTalk {
  margin-right: auto;
}

.revealAnswer {
  margin: auto 0 0 auto;
  position: relative;

  .indicator {
    position: absolute;
    bottom: 0;
    right: 0;
    color: white;
    font-size: 14px;
    padding: 0 4px;
    border-radius: 8px;
    transform: translate(25%, 25%);
    pointer-events: none;
    background: lighten($color: $uiOrange, $amount: 15);
  }
}

.answers {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;

  .answer {
    width: 100%;
    padding: 10px 20px;
    background-color: $primary;
    box-shadow: 3px 3px 0 0 myRgba($primary, 50%);
    border-radius: 10px;
    font-size: 17px;
    color: white;
    font-weight: 600;
    text-align: center;
    transition: all 0.1s ease-in-out;

    &.green {
      background-color: $uiGreen;
      box-shadow: 3px 3px 0 0 darken($uiGreen, 10%);
    }

    &.selected {
      background-color: $uiYellow;
      box-shadow: 3px 3px 0 0 darken($uiYellow, 10%);
      color: $primary900;
    }

    &.disabled {
      background-color: $buttonGrey;
      box-shadow: 3px 3px 0 0 myRgba($buttonGrey, 50%);
      color: white;
      pointer-events: none;
    }
  }
}

.firstTime {
  font-weight: 700;
  color: $uiPurple;
  font-size: 12px;
  text-align: right;
  margin-top: 8px;
}
