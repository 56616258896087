@import '../../styles/variables.scss';

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button {
  width: 100%;
  background-color: $primary;
  border: none;
  border-radius: 10px;
  color: white;
  font-weight: 700;
  padding: 10px;
  font-size: 17px;
  cursor: pointer;
  box-shadow: 0px 3px 0 0 myRgba($primary, 50%);
  transition:
    background-color 0.2s,
    box-shadow 0.2s,
    transform 0.2s;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  gap: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;

  &.delay {
    opacity: 0.8;
    animation: delay linear;
    animation-fill-mode: forwards;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0%;
      width: 0%;
      height: 100%;
      backdrop-filter: brightness(1.7) saturate(0.3);
      animation: delay-after ease-in-out;
      animation-duration: inherit;
      animation-fill-mode: forwards;
    }
  }

  .spinner {
    animation: spinner 1s linear infinite;
    height: 1em;
    width: 1em;
  }

  &.outline {
    background-color: white;
    color: $primary;
    border: 2px solid $primary;
  }

  &.text {
    background-color: transparent;
    color: $primary600;
    border: none;
    box-shadow: none;
    transform: none !important;
  }

  &:disabled {
    cursor: not-allowed;
    transform: none;
  }

  &:disabled,
  &.grey {
    background-color: $buttonGrey;
    color: white;
    box-shadow: 0px 3px 0 0 myRgba($buttonGrey, 50%);

    &.outline {
      background-color: white;
      color: $buttonGrey;
      border: 2px solid $buttonGrey;
    }
  }

  &.black {
    background-color: $buttonBlack;
    color: white;
    box-shadow: 0px 3px 0 0 myRgba($buttonBlack, 50%);

    &.outline {
      background-color: white;
      color: $buttonBlack;
      border: 2px solid $buttonBlack;
    }
  }

  &.red {
    background-color: $uiRed;
    color: white;
    box-shadow: 0px 3px 0 0 myRgba($uiRed, 50%);

    &.outline {
      background-color: white;
      color: $uiRed;
      border: 2px solid $uiRed;
    }
  }

  &.green {
    background-color: $uiGreen;
    color: white;
    box-shadow: 0px 3px 0 0 myRgba($uiGreen, 50%);

    &.outline {
      background-color: white;
      color: $uiGreen;
      border: 2px solid $uiGreen;
    }
  }

  &.yellow {
    background-color: $uiYellow;
    color: white;
    box-shadow: 0px 3px 0 0 myRgba($uiYellow, 50%);

    &.outline {
      background-color: white;
      color: $uiYellow;
      border: 2px solid $uiYellow;
    }
  }

  &.pink {
    background-color: $uiPink;
    color: white;
    box-shadow: 0px 3px 0 0 myRgba($uiPink, 50%);

    &.outline {
      background-color: white;
      color: $uiPink;
      border: 2px solid $uiPink;
    }
  }

  &.purple {
    background-color: $uiPurple;
    color: white;
    box-shadow: 0px 3px 0 0 myRgba($uiPurple, 50%);

    &.outline {
      background-color: white;
      color: $uiPurple;
      border: 2px solid $uiPurple;
    }
  }

  &.orange {
    background-color: $uiOrange;
    color: white;
    box-shadow: 0px 3px 0 0 myRgba($uiOrange, 50%);

    &.outline {
      background-color: white;
      color: $uiOrange;
      border: 2px solid $uiOrange;
    }
  }

  &.dark {
    background-color: $primary800;
    color: white;
    box-shadow: 0px 3px 0 0 myRgba($primary800, 50%);

    &.outline {
      background-color: white;
      color: $primary800;
      border: 2px solid $primary800;
    }
  }

  &:active {
    box-shadow: 0 0 0 0 myRgba($primary, 50%);
    transform: translateY(3px);
  }

  &.blocked {
    border-color: desaturate(darken(#bcdaff, 20), 60);
    box-shadow: 0px 4px 0px 0px desaturate(darken(#bcdaff, 20), 60);

    .offlineOverlay {
      backdrop-filter: brightness(0.8) saturate(0.4);
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;

      > img {
        width: max(32px, 50%);
        height: max(32px, 50%);
        margin: auto;
      }
    }
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

@keyframes delay {
  0% {
    opacity: 0.8;
    pointer-events: none;
  }
  99% {
    opacity: 0.8;
    pointer-events: none;
  }
  100% {
    opacity: 1;
    pointer-events: all;
  }
}

@keyframes delay-after {
  0% {
    left: 0%;
    width: 100%;
  }
  99% {
    left: 100%;
    width: 0%;
  }
  100% {
    left: 100%;
    width: 0%;
  }
}
