.wrapper {
  position: fixed;
  top: 100px;
  width: 100%;
  left: 0;
  height: calc(100% - 100px);
  overflow: hidden;
  z-index: 999999999;

  .card {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    position: absolute;
    width: 100%;
    padding: 30px 20px 30px 20px;
    bottom: 0;

    .slidingHandle {
      height: 24px;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      touch-action: none;
      .rect {
        width: 40px;
        height: 6px;
        background: currentColor;
        border-radius: 4px;
        position: absolute;
        top: 8px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
