@import '../../styles/variables.scss';

.container {
  color: $primary900;
  background-color: $primary100;
  border-radius: 20px;
  padding: 20px;
  width: 50%;
  gap: 20px;
  height: 100%;

  .container2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .number {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 4px;
  }

  .text {
    font-size: 15px;
    font-weight: 500;
  }

  &.small {
    .number {
      font-size: 16px;
    }

    .text {
      font-size: 13px;
    }
  }
}
