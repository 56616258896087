.testimonial {
  width: 90%;
  margin: 0 auto;
  border: 5px solid #FFB080;
  border-radius: 15px;
  padding: 5px;
  display: flex;
  align-items: flex-start;
  position: relative;

  .content {
    padding: 10px;
    padding-right: 5px;
    padding-top: 20px;
    font-weight: 600;
    font-size: 15px;

    .name {
      text-align: right;
      font-size: 13px;
      font-weight: 400;
      margin-top: 20px;
    }
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -15px;
    left: calc(50% - 10px);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #FFB080;
  }
}