@import '../../styles/variables.scss';

.textToolTip {
  font-size: 14px;
  font-weight: 500;
  display: block;
  margin-bottom: 5px;
  gap: 4px;
}

.weekSettings {
  border: 1px solid $primary600;
  border-radius: 10px;
  padding: 20px;
}

.dayButton {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 2px solid $primary600;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: $primary600;
  font-size: 14px;
  font-weight: 700;
  background-color: white;
  transition: all 0.2s ease;

  &.active {
    background-color: $primary600;
    color: white;
  }

  &.first {
    background-color: $primary800;
  }
}

.noDaySelected {
  color: $primary600;
  font-size: 15px;
  font-weight: 500;
  margin-top: 10px;
  text-align: center;
  width: 100%;
}

.dayGroup {
  background-color: white;
  margin-top: 15px;
  padding: 15px;
  border-radius: 10px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  .dayGroupInfos {
    .dayGroupTitle {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 5px;
    }

    .dayGroupContent {
      font-size: 14px;
    }
  }

  .dayGroupButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary100;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    cursor: pointer;

    svg {
      color: $primary600;
      font-size: 20px;
    }
  }
}
