.center {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.text {
  color: #206EBA;
  font-size: 18px;
}