@import '../../styles/variables.scss';
.finishedQuizBonus {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .text {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: $primary900;
  }

  .earnedTime {
    margin: auto;
  }
}
