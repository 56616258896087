.explanation {
  font-size: 14px;
  color: #858585;
  width: 80%;
  margin: 0 auto;
  text-align: center;
}

.descriptionInput {
  width: 100%;
  padding: 11px 17px;
  font-size: 19px;
  border-radius: 10px;
  border: 2px solid #a2c4ee;
  background-color: white;
  font-weight: 500;

  &:focus {
    outline: none;
    border-color: #5f92d2;
  }

  &::placeholder {
    color: #afcff7;
    font-weight: 500;
  }
}

.selectedReason {
  background-color: yellow;
}

.popupContainer {
  display: flex;
  flex-direction: column;
  justify-content: center; // Cela centrera tous les enfants verticalement dans le conteneur
  align-items: center;
  height: 100%;
  position: relative;
}

.closeButton {
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 25px;
  color: #999;
  z-index: 100;
}

.altusTalkWrapper {
  margin-top: 40px;
}
