.application {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #E5E5E5;
  gap: 8px;

  img {
    width: 50px;
    height: 50px;
    border-radius: 7px;
  }

  .name {
    font-size: 15px;
    font-weight: 500;
  }

  .checkbox {
    width: 32px;
    height: 32px;
  }
}
