@import '../../styles/variables.scss';

.textTitleNbr {
  color: $primary800;
  font-size: 20px;
  text-align: center;
  font-weight: 900;
  margin: 0;
  padding: 0;
  .textTitle {
    font-size: 15px;
    margin: 0;
    margin-top: -5px;
    padding: 0;
    font-weight: 600;
  }
}

.textSubtitle {
  color: $primary800;
  font-size: 13px;
  text-align: center;
  margin-top: 0;
}

.containerCircle {
  color: $primary900;
  background-color: $primary200;
  border-radius: 20px;
  padding: 10px;
  flex-direction: column;
  margin-bottom: 10%;
  .text {
    margin: 0;
    color: $primary800;
    font-size: 20px;
    text-align: center;
    font-weight: bold;
  }
}

.card {
  background: $primary100;
  border-radius: 20px;
  padding: 16px;
}

.statsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 16px;
  width: 100%;
  .time {
    grid-row: 1 / span 2;
    grid-column: 1;
  }

  .stats1 {
    grid-column: 2;
    grid-row: 1;
    width: 100%;
  }

  .stats2 {
    grid-column: 2;
    grid-row: 2;
    width: 100%;
  }
}

.title {
  font-weight: bold;
  font-size: 13px;
  color: $primary900;
  display: flex;
  align-items: center;
  gap: 8px;
}

.text {
  color: $primary900;
}

.refreshButton {
  svg {
    color: $primary900;
    font-size: 20px;
  }
}

.tooltip {
  font-weight: 500;
}

.toggleLocalizationButton {
  text-decoration: underline;
  font-size: 13px;
  color: $primary900;
}

.localizationCard {
  padding: 16px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  gap: 32px;
  background-color: white;

  .title {
    font-size: 18px;
    font-weight: bold;
    align-self: center;
  }

  img {
    align-self: center;
  }
}
.mapSection {
  width: 100%;
  .map {
    height: 200px;
    width: 100%;
    border-radius: 16px;
    overflow: hidden;
    border: solid 2px $primary800;
  }
  .mapUpdateText {
    color: $primary900;
    font-size: 13px;
    justify-content: right;
    gap: 4px;
    margin-top: 4px;
    display: flex;
    .warningTriangle {
      color: orange;
      align-self: center;
    }
  }
}

.hardestSession {
  font-weight: 500;
  font-size: 15px;
  color: $primary900;
}
