@import "../../styles/variables.scss";

.subscriptionTimeButton {
  background-color: $primary100;
  border-radius: 20px;
  width: 100%;
  padding: 20px;
  border: none;
  text-align: left;
  color: $primary900;
  background-repeat: no-repeat;
  background-position: right bottom;

  .title {
    font-size: 19px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 15px;

    .percentage {
      background-color: $primary;
      padding: 5px 10px;
      border-radius: 5px;
      color: white;
      font-size: 15px;
    }
  }

  .price {
    font-size: 30px;
    font-weight: 700;
    display: flex;
    align-items: flex-end;
    gap: 15px;

    .perMonth {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 5px;
    }
  }

  .realPrice {
    font-size: 15px;
    font-weight: 500;
  }

}
