@import '../../styles//variables.scss';

.category {
  width: 100%;
  border-bottom: solid 1px $primary200;
  max-width: 100%;

  .title {
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;
    max-width: 100%;

    .checkbox {
      width: 32px;
      height: 32px;
    }

    .categoryButton {
      gap: 8px;
      flex: 1;
      display: flex;
      align-items: center;
      background: none;
      padding: 0;
      border: none;
      h3 {
        color: $primary;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 70vw;
        @media (max-width: 450px) {
          max-width: 60vw;
        }
      }

      p {
        margin-left: auto;
        color: $buttonGrey;
        font-weight: 600;
      }

      .chevronRight {
        color: $buttonGrey;
      }
    }
  }

  .backButton {
    background: none;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 8px;
    color: $primary;
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 16px;
    text-decoration: underline;
  }
}
