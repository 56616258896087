@import '../../styles/variables.scss';

.altusTalk {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;

  &.shine {
    padding: 80px 0px 190px 0;
  }

  .message {
    background-color: #f3f8fe;
    border: 2px solid #d9e5f3;
    color: $primary900;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 15px;
    max-width: 80%;
    font-weight: 500;
    font-size: 18px;
    position: relative;
    animation: upAndDown 3s infinite;

    & > div {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -10px;
      left: calc(50% - 10px);
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #d9e5f3;
    }
  }

  &.small {
    flex-direction: row-reverse;
    gap: 10px;
    align-items: flex-start;

    .message {
      max-width: 100%;
      margin-bottom: 0;
      margin-left: 0;
      padding: 10px;
      font-size: 16px;
      animation: leftAndRight 3s infinite;

      &::after {
        left: -20px;
        top: 20px;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 10px solid #d9e5f3;
      }
    }
  }
}

@keyframes upAndDown {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-6px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes leftAndRight {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(6px);
  }
  100% {
    transform: translateX(0px);
  }
}
