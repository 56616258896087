@import '../../styles/variables.scss';

.onboarding {
  position: fixed;
  bottom: 90px;
  left: 0;
  width: 100%;
  height: calc(100% - 90px);
  background: #00000099;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 1000;

  .popup {
    margin-bottom: 30px;
    background: white;
    border-radius: 15px;
    border: 2px solid $primary;
    width: 90%;
    padding: 20px;

    svg {
      font-size: 40px;
    }

    .title {
      font-size: 25px;
      font-weight: bold;
      margin-bottom: 10px;
      margin-top: 10px;
    }

    .text {
      font-size: 16px;
      color: #777;
      font-weight: 500;
      margin-bottom: 20px;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 15px;
      left: 50%;
      transform: translateX(-50%);
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 15px solid $primary;
      transition:
        left 0.2s ease-in-out,
        border-top 0.2s ease-in-out;
    }

    &.home {
      &::after {
        border-top: 15px solid $primary;
        left: 13%;
      }
    }

    &.chapters {
      &::after {
        border-top: 15px solid $uiOrange;
        left: 30%;
      }
    }

    &.stats {
      &::after {
        border-top: 15px solid $uiGreen;
        left: 50%;
      }
    }

    &.parental-control {
      &::after {
        border-top: 15px solid $uiBlue;
        left: 70%;
      }
    }

    &.settings {
      &::after {
        border-top: 15px solid $primary800;
        left: 88%;
      }
    }
  }
}

.bar {
  width: 100%;
  background: white;
  border-top: 3px solid #e5e5e5;
  height: 90px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 35px;
  flex-shrink: 0;

  > * {
    padding: 10px;
    height: 50px;
    width: 50px;
    transition:
      background-color 0.2s ease-in-out,
      color 0.2s ease-in-out;
  }

  > :first-child {
    color: $primary;

    &.active {
      background-color: $primary;
    }
  }

  > :nth-child(2) {
    color: $uiOrange;
    &.active {
      background-color: $uiOrange;
    }
  }

  > :nth-child(3) {
    color: $uiGreen;
    &.active {
      background-color: $uiGreen;
    }
  }

  > :nth-child(4) {
    color: $uiBlue;
    &.active {
      background-color: $uiBlue;
    }
  }

  > :nth-child(5) {
    color: $primary800;
    &.active {
      background-color: $primary800;
    }
  }

  .active {
    color: white;
    border-radius: 5px;
  }
}
