.inputWrapper {
  width: 100%;
  position: relative;

  .label {
    font-size: 14px;
    font-weight: 500;
    display: block;
    margin-bottom: 5px;
    display: flex;
    gap: 4px;
  }
}

.textToolTip {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  display: block;
  margin-bottom: 5px;
  gap: 4px;
}
