.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: #0000007f;
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 48px;
  backdrop-filter: blur(1px);
  justify-content: center;

  color: white;
  font-size: 20px;
  font-weight: 600;

  .textWrapper {
    text-align: center;
    align-self: center;
    width: 90%;

    .text {
      margin-bottom: 8px;
    }
  }

  .ctaButton {
    width: calc(100% - 32px);
    margin: 0 auto;
  }
}
