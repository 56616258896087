@import '../../styles/variables.scss';

.faqQuestion {
  width: 100%;
  background-color: $primary100;
  border: 1px solid $primary300;
  padding: 15px;
  border-radius: 10px;
  color: $primary900;

  .question {
    font-size: 15px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 10px;

    .icon {
      transition: transform 0.2s ease-in-out;
      flex-shrink: 0;
    }
  }

  .answer {
    font-size: 14px;
    overflow: hidden;

    &:not(.open) {
      height: 0;
    }

    &.open {
      margin-top: 10px;
    }
  }
}
