@import '../../styles/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  padding: 0 20px;
  text-align: center;

  @media (max-height: 800px) {
    padding: 0 10px;
    margin-top: -40px;
  }

  .childName {
    font-size: 33px;
    font-weight: 800;
    margin-bottom: 0px;
    color: #0b2d5b;

    @media (max-height: 800px) {
      font-size: 28px;
    }
  }

  .blueText {
    color: $uiBlue;
    font-size: 24px;
    font-weight: 700;

    @media (max-height: 800px) {
      font-size: 20px;
    }
  }

  .littleBlueText {
    color: $uiBlue;
    font-size: 19px;
    font-weight: 700;

    @media (max-height: 800px) {
      font-size: 17px;
    }
  }

  .timeTag {
    background-color: #0b2d5b;
    color: white;
    padding: 7px 20px;
    border-radius: 5px;
    font-size: 28px;
    margin: 15px 0;

    .time {
      font-weight: 700;
    }
  }

  .progressBar {
    margin-top: 20px;
    width: 50vw;
    max-width: 250px;
    height: 50vw;
    max-height: 250px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;

    @media (max-height: 800px) {
      width: 40vw;
      max-width: 200px;
      height: 40vw;
      max-height: 200px;
      margin-bottom: 10px;
      margin-top: 10px;
    }

    .time {
      font-size: 45px;
      font-weight: 700;
      color: $primary;
      margin-top: -10px;

      @media (max-height: 800px) {
        font-size: 35px;
      }
    }

    .minutes {
      font-size: 20px;
      font-weight: 700;
      color: $primary;
      margin-top: -10px;

      @media (max-height: 800px) {
        font-size: 15px;
      }
    }

    .text {
      font-size: 15px;
      font-weight: 600;
      color: #00000066;
      max-width: 70%;
      margin-top: 5px;

      @media (max-height: 800px) {
        font-size: 13px;
        margin-top: 0;
        max-width: 80%;
      }
    }
  }
}

.sliderWrapper {
  width: 100%;
  margin-top: 20px;

  @media (max-height: 800px) {
    margin-top: 10px;
  }

  .slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 40px;
    border-radius: 20px;
    background: #bcd8ff;
    padding: 5px;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: #3e91e1;
      cursor: pointer;
    }

    &::-moz-range-thumb {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: #3e91e1;
      cursor: pointer;
    }
  }

  .sliderText {
    text-align: center;
    margin-top: 15px;
    font-weight: 600;
    color: #85b4f7;
    font-size: 15px;
  }
}
