@import '../../styles/variables.scss';

.altusPlus {
  color: white;
  font-weight: bold;
  font-size: 20px;
  .plus {
    color: white;
    background: $primary500;
    padding: 4px;
    border-radius: 6px;
  }
}

.altusPlusLight {
  color: $primary900;
}

.banner {
  background: $primary300;
  padding: 16px;
  width: calc(100% - 32px);
  margin-top: 16px;
  border-radius: 16px;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  .bannerTitle {
    color: white;
    font-size: 20px;
    font-weight: 700;
  }
  .bannerText {
    color: $primary900;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .chevronRight {
    height: 32px;
    width: 32px;
  }
}

.backHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 0;
  .backButton {
    color: white;
  }
}

.subscribeButtons {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
}
