@import '../../styles/variables.scss';

.child {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 500;
  font-size: 15px;
  gap: 7px;
  width: 38%;
  background: none;
  border: none;

  &.disabled {
    opacity: 0.5;
  }

  .icon {
    width: 80px;
    height: 80px;
    border-radius: 20px;
    background: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-weight: 600;
    color: white;
    flex-direction: column;
    gap: 4px;

    .plus {
      background-color: white;
      color: $primary;
      font-size: 14px;
      padding: 4px;
      border-radius: 8px;
      font-weight: bold;
    }
  }

  .name {
    color: #4f4f4f;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
