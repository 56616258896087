@import "../../styles/variables.scss";

.onboardingHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  // background-color: white;
  z-index: 1000;

  button {
    background-color: white;
    border: none;
    border-radius: 50%;
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .progressBar {
    width: 65%;
    height: 10px;
    background-color: #D0E6FF;
    position: relative;
    margin: 0 20px;
    border-radius: 10px;

    .progress {
      transition: all 0.3s ease-in-out;
      position: absolute;
      top: 0;
      left: 0;
      height: 10px;
      background-color: $primary;
      border-radius: 10px;
    }
  }
  .space {
    width: 36px;
  }
}