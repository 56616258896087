@import '../../styles/variables.scss';

.fieldButton {
  display: grid;
  grid-template-columns: 48px 1fr 42px;
  gap: 8px;
  border: none;
  border-radius: 10px;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s ease-in-out;
  z-index: 1;
  width: 100%;
  .fieldIcon {
    width: 30px;
    height: 30px;
    font-size: 17px;
    color: #ffffff;
    margin-left: 12px;
    font-weight: 700;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
      width: 100%;
      height: 100%;
    }

    > svg {
      font-size: 30px;
    }
  }

  .fieldName {
    color: white;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    flex-grow: 1;
    width: 100%;
  }

  .fieldPercentage {
    color: #00000088;
    font-size: 16px;
    font-weight: 700;
    text-align: right;
    margin-right: 15px;
    flex-shrink: 0;
  }

  &:active {
    box-shadow: none !important;
    transform: translateY(4px);
  }
}

.subtitleWrapper {
  position: relative;
  img {
    position: absolute;
    z-index: 0;
    right: 0;
    top: -25px;
  }
  width: 100%;
}
.subtitle {
  color: $primary800;
  font-size: 15px;
  font-weight: 700;
  text-align: left;
  margin-top: 10px;
  width: 100%;
}
