@import '../../styles/variables.scss';

.dialogWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000001;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 16px;

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }

  .content {
    padding: 16px;
    background: white;
    border: solid 2px $primary500;
    border-radius: 20px;
    color: $primary900;
  }
}
