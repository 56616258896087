@import '../../styles/variables.scss';

.tabs {
  width: 100%;
  height: 50px;
  border-bottom: 2px solid $primary200;
  display: flex;
  margin-bottom: 10px;

  .tab {
    width: 100%;
    text-align: center;
    padding: 15px 0;
    margin-bottom: -2px;
    border-bottom: 2px solid transparent;
    transition: border-color 0.2s ease-in-out;
    color: $primary800;
    font-size: 15px;
    font-weight: 500;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    &.active {
      border-bottom: 2px solid $primary500;
      font-weight: 700;
      background-color: $primary100;
    }
  }
}
