.labeledSwitch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .label {
    font-size: 14px;
    font-weight: 500;
    display: block;
    margin-bottom: 5px;
    display: flex;
    gap: 4px;
    align-items: center;
    gap: 10px;
  }
}
