@import '../../styles/variables.scss';

.statsTimeRangeSelector {
  width: 100%;
  margin-bottom: 30px;
  border-top: 1px solid $primary300;
  padding-top: 20px;

  .timeRangeSelector {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
    margin-bottom: 20px;
    width: 100%;

    .button {
      background-color: $primary300;
      color: white;
      border: none;
      padding: 10px;
      border-radius: 10px;
      font-weight: 700;
      font-size: 15px;
      display: flex;
      justify-content: center;
      gap: 4px;
      position: relative;

      .plus {
        background: white;
        color: $primary300;
        padding: 2px;
        border-radius: 4px;
        font-size: 12px;
        align-self: center;
      }

      &.active {
        background-color: $primary500;

        .plus {
          color: $primary500;
        }
      }
    }
  }

  .timePeriodSelector {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      background-color: $primary500;
      color: white;
      border: none;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        font-size: 20px;
        width: 20px;
        height: 20px;
      }

      &.disabled {
        background-color: $primary300;
      }
    }

    .timePeriod {
      font-size: 14px;
      font-weight: 600;
      color: $primary900;
    }
  }
}
