@import '../../styles/variables.scss';

.fieldTitle {
  margin-top: 85px;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 600;
  color: white;
  padding: 0px 20px;

  button {
    background-color: #00000088;
    border: none;
    border-radius: 10px;
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title {
    font-size: 16px;
    flex-grow: 1;
    text-align: center;
    font-weight: 700;
    color: white;
  }

  .space {
    width: 36px;
    display: flex;
    align-items: center;
  }
}

.wrapper {
  height: calc(100% - 70px);
  padding-bottom: 150px;
  position: relative;
  overflow-y: auto;

  &.nonScrollable {
    overflow-y: hidden;
  }

  .monthTitle {
    position: sticky;
    top: 10px;
    left: 15px;
    width: calc(100% - 30px);
    z-index: 99;
    padding: 20px;
    // border-bottom: 2px solid $primary650;
    color: white;
    text-align: center;
    font-weight: 600;
    border-radius: 10px;
    // margin-top: 30px;
  }

  .childrenWorkedChapters {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
    padding-bottom: 30px;
  }
}
