@import '../../styles/variables.scss';

.wrapper {
  position: relative;
}

$marginBottom: 60px;

.workedChapter {
  width: 80px;
  height: 65px;
  box-shadow: 0px 7px 0 0 $primary600;
  border-radius: 50%;
  margin-bottom: $marginBottom;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 20px;
  font-weight: 700;

  svg {
    font-size: 30px;
  }

  img {
    width: 40px;
    border-radius: 5px;
  }

  &:active {
    box-shadow: 0px 0px 0 0 $primary600 !important;
    margin-top: 5px;
    margin-bottom: calc(#{$marginBottom} - 5px);
  }

  .stars {
    position: absolute;
    top: calc(100% + 5px);
    display: flex;
    gap: 10px;

    svg {
      width: 22px;
      height: 22px;
      fill: white;
    }

    svg:nth-child(2) {
      margin-top: 10px;
    }
  }

  &:active {
    .stars {
      top: calc(100%);
    }
  }

  &.notWorked {
    background-color: #e5e5e5 !important;
    box-shadow: 0px 7px 0 0 darken(#e5e5e5, 10%) !important;
    margin-bottom: 20px;

    &:active {
      box-shadow: 0px 0px 0 0 darken(#e5e5e5, 10%) !important;
      margin-top: 5px;
      margin-bottom: 15px;
    }

    .stars {
      display: none;
    }

    img {
      filter: grayscale(100%);
    }
  }

  &.noTry {
    opacity: 0.5;
  }

  &.maxElo {
    background-color: $uiYellow;
    box-shadow: 0px 7px 0 0 darken($uiYellow, 10%);
  }
}

.workedChapterAltus {
  position: absolute;
  top: -10px;

  &.left {
    left: 90px;
  }

  &.right {
    right: 90px;
  }
}

.workedChapterPopup {
  position: absolute;
  background-color: white;
  width: 300px;
  border: 2px solid $primary;
  border-radius: 20px;
  top: -30px;
  transform: translateY(-100%);
  left: calc(50% - 150px);
  padding: 20px;
  z-index: 1000;
  box-shadow: 0px 3px 0px 0px $primary550;

  $size: 12px;
  &::after {
    content: '';
    position: absolute;
    bottom: -$size - 3px;
    left: calc(50% - $size);
    width: 0;
    height: 0;
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $primary550;
  }

  &.left {
    &::after {
      left: calc(31% - $size);
    }
  }

  &.right {
    &::after {
      left: calc(69% - $size);
    }
  }

  .field {
    font-weight: 700;
    font-size: 13px;
    color: $primary;
  }

  .chapter {
    font-weight: 500;
    font-size: 15px;
    color: $primary800;
    margin-bottom: 10px;
  }

  .progress {
    width: 100%;
    height: 8px;
    background-color: #e5e5e5;
    border-radius: 10px;
    position: relative;
    margin-bottom: 20px;

    .progressValue {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background-color: $primary;
      border-radius: 10px;
    }
  }
}
