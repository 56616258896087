@import '../../styles/variables.scss';

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
}

.forgot {
  background: none;
  border: none;
  color: $primary;
  font-weight: 600;
  width: 100%;
  text-align: center;
  font-size: 14px;
  margin-top: 10px;
}
