@import '../../styles/variables.scss';

.categoryName {
  font-size: 20px;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 15px;
  color: $primary900;
  text-align: center;
  width: 100%;
}
