.switch {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 20px;
  margin: 0 10px;
  cursor: pointer;
  user-select: none;
  border-radius: 20px;
  background-color: #ccc;
  transition: background-color 0.2s ease;
  border: none;

  &:after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transition: left 0.2s ease;
    background: linear-gradient(180deg, #79BDFF 0%, #3E91E1 100%);
  }

  &.checked {
    background-color: #93D2FA;

    &:after {
      left: 30px;
    }
  }
}