@import '../../styles/variables.scss';

.backHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $primary;
  z-index: 1000;
  min-height: 67px;
  border-bottom: 2px solid $primary550;
  transition:
    background-color 0.2s ease-in-out,
    border-bottom 0.2s ease-in-out;

  button {
    background-color: $primary800;
    border: none;
    border-radius: 10px;
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease-in-out;
  }

  .title {
    font-size: 16px;
    flex-grow: 1;
    text-align: center;
    font-weight: 700;
    color: white;
    height: 100%;
  }

  .space {
    width: 36px;
    display: flex;
    align-items: center;
  }
}
