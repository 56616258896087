@import '../../styles/variables.scss';

.correction {
  background-color: lighten($uiGreen, 28%);
  color: darken($uiGreen, 20%);
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;

  .header {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      color: darken($uiGreen, 20%);
      background-color: transparent;
      border: none;
      padding: 0;
      cursor: pointer;
      outline: none;
      font-size: 25px;
    }
  }

  .text {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
  }

  .earnedTimeWrapper {
    margin: 20px auto;
    display: flex;
    flex-direction: column;

    .streak {
      color: darken($uiGreen, 20%);
      font-weight: bold;
      text-align: center;
      font-size: 14px;
      margin-bottom: 4px;
    }

    .earnedTime {
      margin: auto;
    }
  }
}
