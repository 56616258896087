@import "../../styles/variables.scss";

.result {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $primary100;
  border-radius: 10px;
  padding: 10px 20px;

  .left {
    font-size: 13px;
    font-weight: 500;
  }

  .right {
    font-size: 17px;
    font-weight: 700;
  }
}
