@import '../../styles/variables.scss';

.inputWrapper {
  width: 100%;
  position: relative;

  .label {
    font-size: 14px;
    font-weight: 500;
    display: block;
    margin-bottom: 5px;
    display: flex;
    gap: 4px;
  }
}

.select {
  width: 100%;
  padding: 11px 17px;
  font-size: 19px;
  border-radius: 10px;
  border: 2px solid $primary300;
  background-color: white;
  font-weight: 500;

  &::placeholder {
    color: #bdbdbd;
  }
}
