@import '../../styles/variables';

.card {
  background-color: $primary200;
  box-shadow: 0px 4px 0px 0px $primary400;
  width: 100%;
  padding: 10px 10px 10px 20px;
  border-radius: 15px;
  color: white;
  position: relative;

  .deleteButton {
    position: absolute;
    top: -10px;
    left: -10px;
    height: 25px;
    width: 25px;
    background-color: #ffd426;
    border-radius: 50%;
    border: none;
    color: $primary800;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    font-weight: 600;

    .title {
      display: flex;
      align-items: center;
      gap: 10px;

      .icon {
        width: 30px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;

        svg {
          font-size: 20px;
        }

        img {
          width: 60%;
          height: 100%;
        }
      }
    }

    .limit {
      font-size: 12px;
      color: #00000090;
      font-weight: 500;
    }

    .moreButton {
      background-color: white;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        transform: rotate(270deg);
        transition: transform 0.3s;
      }

      &.expanded {
        img {
          transform: rotate(90deg);
        }
      }
    }

    .select {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: white;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
      display: flex;
      margin-right: 3px;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      transition: background-color 0.2s;

      &.selected {
        background-color: $primary;
      }

      img {
        width: 15px;
        height: 15px;
      }
    }
  }

  .content {
    padding-top: 20px;

    .subChapter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 15px;
      margin-bottom: 20px;

      &.selectAll {
        font-weight: 600;
      }
    }
  }
}
