.option {
  color: #1a72c6;
  border-radius: 100px;
  background: #c6dffb;
  box-shadow: 0px 4px 0px 0px #a9cff9;
  padding: 10px 10px 10px 30px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-height: 720px) {
    padding: 5px 5px 5px 20px;
  }

  &.selected {
  }

  .check {
    background-color: white;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;

    > img {
      opacity: 0;
      height: 30px;
      width: 30px;
      margin-left: 5px;
    }

    &.selected {
      background-color: #72f06f;
      box-shadow: 0px 3px 0px 0px #57db53;

      > img {
        opacity: 1;
      }
    }
  }
}
