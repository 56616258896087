@import '../../styles/variables.scss';

.startSession {
  width: 100%;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  flex-direction: column;
  font-size: 16px;
  font-weight: 700;
  border: none;
  background-color: $primary100;
  color: $primary800;
  gap: 10px;

  svg {
    font-size: 50px;
    margin-top: 20px;
  }
}

.startSessionLabel {
  font-size: 20px;
  color: $primary;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
}

.startSessionTime {
  font-size: 30px;
  color: $primary800;
  font-weight: 700;
  margin-top: 20px;
}

.startSessionExplanation {
  font-size: 16px;
  color: $primary800;
  font-weight: 500;
  text-align: center;
  width: 80%;
}
