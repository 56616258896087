.title {
  color: #1A72C6;
  font-size: 19px;
  font-weight: 600;
  margin-top: 15px;
  text-align: center;
  margin-bottom: 0;
}

.card {
  > div {
    width: 50%;

    > img {
      width: 100%;
    }
  }
  &.cutted {
    > div {
      width: 100%;

      > img {
        width: 100%;
      }
    }
  }
}