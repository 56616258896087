@import '../../styles/variables.scss';

.title {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  margin-top: 40px;
  max-width: 250px;
  color: $primary900;
  .plus {
    margin-top: 8px;
  }
}

.error {
  color: $uiRed;
  font-size: 14px;
}
.hidden {
  opacity: 0;
}
