@import '../../styles/variables.scss';

.childrenHeader {
  position: fixed;
  top: 0;
  left: 0;
  background-color: $primary;
  border-bottom: 2px solid $primary650;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  z-index: 100;
  width: 100%;
  transition: background-color 0.2s ease-in-out;

  .level {
    color: white;
    font-weight: 700;
    font-size: 17px;
    text-align: center;
    width: 100%;
  }

  .levelBar {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
  }

  .progress {
    width: 100%;
    height: 8px;
    background-color: $primary650;
    border-radius: 10px;
    position: relative;
    transition: background-color 0.2s ease-in-out;

    .progressValue {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background-color: white;
      border-radius: 10px;
      transition: width 0.4s ease-in-out;
    }
  }

  .offline {
    color: white;
    margin-top: auto;
    img {
      width: 24px;
      height: 24px;
    }
  }

  .loginStreak {
    // position: relative;
    display: flex;
    align-items: center;
    gap: 7px;
    width: 30px;

    img {
      width: 23px;
    }

    .loginStreakText {
      color: white;
      font-size: 18px;
      font-weight: 700;
      // position: absolute;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -64%);
    }
  }
}
