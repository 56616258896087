@import '../../styles/variables';

.bottom {
  background-color: $primary50;
  width: 100%;
  padding-top: 60px !important;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background: linear-gradient(
      180deg,
      myRgba($primary, 15%) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    z-index: 1;
  }
}
