@import '../../styles/variables.scss';

.background {
  background: $primary300;
  height: 100%;
}

.subscriptionText {
  font-weight: bold;
  text-align: center;
  font-size: 24px;
  color: $primary900;
  b {
    color: $primary600;
  }
}

.marginAuto {
  margin: auto;
}

.roundedBox {
  border: solid 4px $primary500;
  border-radius: 24px;
  width: calc(100% - 16px);
  padding: 24px;
  gap: 16px;
  display: flex;
  flex-direction: column;

  .point {
    display: flex;
    color: $primary800;
    gap: 16px;
    .icon,
    .icon > svg {
      height: 24px;
      width: 24px;
    }

    .text {
      color: $primary700;

      .title {
        font-weight: bold;
      }
    }
  }
}

.comparison {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 80px 80px;
  grid-template-rows: 48px repeat(5, auto);
  width: 100%;
  padding-top: 60px;

  .point {
    color: $primary700;
    font-weight: 550;
    text-align: left;
    align-self: center;
    grid-column: 1;
    font-size: 18px;
    padding: 16px 0;
  }

  .altusHold {
    position: absolute;
    right: -4px;
    top: 68px;
    transform: translateY(-100%);
  }

  .lastColumnBg {
    grid-column: 3;
    grid-row: 1 / span 6;
    background: $primary400;
    border-radius: 16px;
  }

  .sep1,
  .sep2,
  .sep3,
  .sep4 {
    width: 100%;
    height: 0;
    border-bottom: solid 1px $primary900;
    grid-column: 1 / span 4;
  }

  .sep1 {
    grid-row: 3;
  }
  .sep2 {
    grid-row: 4;
  }
  .sep3 {
    grid-row: 5;
  }
  .sep4 {
    grid-row: 6;
  }

  .headerFree,
  .headerPlus {
    text-align: center;
    z-index: 1;
    grid-row: 1;
    font-weight: bold;
    align-self: center;
    font-size: 18px;
  }
  .headerPlus {
    color: white;
    border-radius: 8px;
    background: $primary500;
    width: fit-content;
    padding: 4px;
    grid-column: 3;
    margin: auto;
  }
  .headerFree {
    grid-column: 2;
    color: $primary700;
  }

  .freePoint,
  .plusPoint1,
  .plusPoint2,
  .plusPoint3,
  .plusPoint4,
  .plusPoint5 {
    margin: auto;
    color: white;
    width: 24px;
    height: 24px;
  }

  .plusPoint1,
  .plusPoint2,
  .plusPoint3,
  .plusPoint4,
  .plusPoint5 {
    grid-column: 3;
  }

  .freePoint {
    grid-column: 2;
    grid-row: 2;
  }
  .plusPoint1 {
    grid-row: 2;
  }
  .plusPoint2 {
    grid-row: 3;
  }
  .plusPoint3 {
    grid-row: 4;
  }
  .plusPoint4 {
    grid-row: 5;
  }
  .plusPoint5 {
    grid-row: 6;
  }
}

.trialTimeframe {
  display: grid;
  grid-template-columns: auto 1fr;
  width: 100%;
  position: relative;

  .icons {
    color: white;
    background: $primary500;
    padding: 32px 8px;
    border-radius: 24px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > svg {
      height: 24px;
      width: 24px;
    }
  }

  .gradient {
    position: absolute;
    background: linear-gradient($primary, transparent);
    height: 100px;
    width: 40px;
    left: 0;
    bottom: 50px;
    transform: translateY(100%);
    z-index: 0;
  }

  .texts {
    color: $primary700;
    font-size: 16px;
    padding-left: 16px;
    gap: 48px;
    display: flex;
    flex-direction: column;
    .title {
      font-weight: bold;
      font-size: 20px;
    }
  }
}

.offerWrapper {
  width: 100%;
  .lockedOfferExplanation {
    font-size: 14px;
    text-align: center;
    margin-bottom: 16px;
    color: $primary700;
  }
}
