@import '../../styles/variables.scss';

.timeLeft {
  font-size: 40px;
  color: $primary800;
  font-weight: 700;
}

.timeLeftLabel {
  font-size: 20px;
  color: $primary800;
  font-weight: 700;
}

.maxDailyProgress {
  width: 100%;
  height: 10px;
  background-color: $primary300;
  border-radius: 10px;
  margin-bottom: 30px;

  .maxDailyProgressFill {
    height: 100%;
    background-color: $primary600;
    border-radius: 10px;
  }

  .maxDailyProgressLabel {
    font-size: 15px;
    color: $primary800;
    font-weight: 500;
    text-align: center;
    margin-top: 10px;
  }
}
.maxDailyLabel {
  font-size: 15px;
  color: $primary800;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}

.askForTime {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 16px;

  .text {
    font-weight: bold;
    font-size: 16px;
    color: $primary800;
    p {
      font-size: 32px;
      margin: 4px;
    }
  }
}

.card {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  flex-shrink: 0;
}

.warningCard {
  background: transparentize($color: $uiYellow, $amount: 0.6);
  border: 2px solid $uiYellow;
  box-shadow: 0px 4px 0px 0px darken($uiYellow, 10%);
  color: #303030;
  .exclamationTriangle {
    font-size: 32px;
    flex-shrink: 0;
  }
  .warningText {
    width: 100%;
    font-weight: 600;
  }
}

.parentWarningCard {
  background: transparentize($color: #ae9df2, $amount: 0.6);
  border: 2px solid #ae9df2;
  box-shadow: 0px 4px 0px 0px desaturate($color: darken(#ae9df2, 10%), $amount: 10%);
  color: #303030;
  .icon {
    font-size: 32px;
    flex-shrink: 0;
  }
  .warningText {
    width: 100%;
    font-weight: 600;
  }
}
