@import '../../styles/variables.scss';

.tutorialCard {
  width: 100%;
  background-color: $primary100;
  border: 1px solid $primary300;
  border-radius: 10px;

  .link {
    display: flex;
    color: $primary900;
    font-weight: 700;
    font-size: 15px;

    .thumbnail {
      width: 60px;
      height: 110px;
      border-radius: 10px;
      margin-right: 15px;
      background-position: center;
      background-size: cover;
      flex-shrink: 0;
    }

    .infos {
      padding: 10px;
      display: flex;
      flex-direction: column;
      // align-items: center;

      .cta {
        margin-top: 10px;
        font-weight: 500;
        font-size: 13px;
        opacity: 0.7;
      }
    }
  }
}
