@import '../../styles/variables.scss';

.tooltip {
  position: fixed;
  background: $primary100;
  left: 50%;
  transform: translate(-50%, 5px);
  width: 90%;
  padding: 15px;
  font-size: 20px;
  border-radius: 12px;
  border: solid 2px $primary400;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.2);
  z-index: 1;

  * {
    font-size: 15px !important;
  }

  .tooltipTriangle {
    position: absolute;
    top: -10px;
    left: calc(50% - 10px);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid $primary100;

    &::before {
      content: '';
      position: absolute;
      top: -3px;
      left: -13px;
      width: 0;
      height: 0;
      border-left: 13px solid transparent;
      border-right: 13px solid transparent;
      border-bottom: 13px solid $primary400;
      z-index: -1;
    }
  }
}
.tooltipBg {
  position: fixed;
  background: #00000020;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.tooltipLink {
  margin-top: 10px;
  display: block;
}
