@import '../../styles/variables.scss';


.progressBar {
  .text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  > svg {
    transform: rotate(-90deg);
  }
}
