@import '../../styles/variables.scss';

.title {
  display: flex;
  flex-direction: column;
  .plus {
    color: $primary500;
    background: white;
    padding: 4px;
    width: fit-content;
    margin: auto;
    border-radius: 8px;
    transform: translateY(8px);
  }
}
