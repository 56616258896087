@import '../../styles/variables.scss';

.textTitle {
  color: $primary800;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  margin: 0;
  padding: 0;
}

.textTitleNbr {
  color: $primary800;
  text-align: center;
  font-weight: 900;
  margin: 0;
  padding: 0;
  font-size: 35px;
  margin-right: 5px;
}

.textSubtitle {
  color: $primary800;
  font-size: 13px;
  text-align: center;
  margin: 0;
}
