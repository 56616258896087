@import '../../styles/variables.scss';

.grid {
  display: grid;
  grid-template-rows: 1fr auto;
  max-height: 100%;
  height: 100%;

  .text, .title {
    text-align: center;
    color: $primary900;
    font-weight: bold;
  }

  .title {
    font-size: larger;
    margin-bottom: 16px;
  }
}
