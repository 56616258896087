.wrapper {
  position: relative;
  width: 100%;
  .locker {
    width: 100%;

    &.locked {
      filter: blur(10px);
    }
  }
  .lockerOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;

    .textWrapper {
      text-align: center;
      align-self: center;
      width: 90%;

      .text {
        margin-bottom: 8px;
      }
    }

    .ctaButton {
      width: calc(100% - 32px);
      margin: 0 auto;
    }
  }
}
