@import '../../styles/variables.scss';

.sessionEarnedTime {
  background-color: $primary850;
  color: white;
  display: flex;
  align-items: center;
  border-radius: 30px;

  .icon {
    padding: 10px;
    font-size: 25px;
    display: flex;
    align-items: center;
  }

  .label {
    font-weight: 600;
    padding-right: 15px;
    width: 128px;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    transition:
      width 0.3s ease-in-out,
      padding 0.3s ease-in-out;
  }

  &.final {
    background-color: $uiOrange;
    transform: scale(1.3);

    .label {
      width: 120px;
    }
  }

  &.zero {
    background-color: $uiRed;
  }

  &.folded {
    .label {
      width: 0;
      padding: 0;
    }
  }
}
