@import '../../styles/variables.scss';

.subscriptionButton {
  background-color: $primary100;
  border-radius: 20px;
  padding: 20px;
  width: 100%;
  padding-bottom: 50px;
  border: none;
  text-align: left;
  color: $primary900;
  background-repeat: no-repeat;
  background-position: right bottom;

  .count {
    font-size: 19px;
    font-weight: 600;
  }

  .from {
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
  }

  .price {
    font-size: 30px;
    font-weight: 700;
  }

  .perMonth {
    font-size: 14px;
    font-weight: 500;
  }

  &:disabled {
    filter: opacity(0.5);
  }
}
