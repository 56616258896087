.sessionLoseLife {
  position: relative;
  animation: zoomIn 2s ease-in-out;
  animation-fill-mode: forwards;
  img {
    width: 40px;
  }

  .text {
    color: white;
    font-size: 18px;
    font-weight: 700;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -64%);
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
}