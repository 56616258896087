@import '../../styles/variables.scss';

.button {
  height: 24px;
  width: 24px;
  padding: 0;
  border: none;
  background: none;

  .checkbox {
    color: $primary;
    height: 100%;
    width: 100%;
  }
  .checkboxEmpty {
    color: $buttonGrey;
    height: 100%;
    width: 100%;
  }
}
