@import '../../styles/variables.scss';

.inputWrapper {
  position: relative;
  width: 100%;

  .label {
    font-size: 14px;
    font-weight: 500;
    display: block;
    margin-bottom: 5px;
    display: flex;
    gap: 4px;
  }

  .inputText {
    width: 100%;
    padding: 11px 17px;
    font-size: 19px;
    border-radius: 10px;
    border: 2px solid $primary300;
    background-color: white;
    font-weight: 500;

    &:focus {
      outline: none;
      border-color: $primary650;
    }

    &::placeholder {
      color: $primary300;
      font-weight: 500;
    }
  }
}

.showPassword {
  position: absolute;
  right: 10px;
  top: 11px;
  font-size: 20px;
  background: none;
  color: #888;
  border: none;
  cursor: pointer;
}
