@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');
@import './variables.scss';

html {
  height: 100%;
  overflow: hidden;
  position: relative;
}
body {
  overflow: auto;
  position: relative;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

a {
  color: $primary;
  text-decoration: none;
}

* {
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
  user-select: none;
}

img {
  -drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

#root {
  height: 100%;
  overflow: hidden;
}

button {
  -webkit-tap-highlight-color: transparent;
}
