@import '../../styles/variables.scss';

.line {
  width: 100%;
  height: 1px;
  background: $primary600;
}

.or {
  color: $primary700;
}
