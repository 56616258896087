@import "../../styles/variables.scss";

.sessionHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  border-bottom: 2px solid #e5e5e5;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 20px 20px;

  .left, .right {
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      background-color: transparent;
      border: none;
      padding: 0;
      cursor: pointer;
      outline: none;
      font-size: 25px;
      color: #999;
    }
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    flex-grow: 1;
    padding: 0 20px;

    .progress {
      position: relative;
      height: 13px;
      width: 100%;
      background-color: #e5e5e5;
      border-radius: 10px;
      margin-top: 5px;

      .progressValue {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-color: $primary;
        border-radius: 10px;
        transition: width 0.2s ease-in-out;
      }
    }
  }

  .right {
    position: relative;
    .heartIcon {
      width: 38px;
    }

    .lifes {
      color: white;
      font-size: 18px;
      font-weight: 700;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -60%);
    }
  }

  .progressText {
    font-size: 14px;
    font-weight: 600;
    color: $primary800;
  }
}
