@import '../../styles/variables.scss';

.page {
  width: 100%;
  height: 100vh;
  background-color: $background;
}

.updateTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.loader {
  flex-shrink: 0;
}
