@import '../../styles/variables.scss';

.leftIcon {
  justify-self: center;
  margin: 5px;
  grid-column: 1;
}

.middleIcon {
  justify-self: center;
  margin: 5px;
  grid-column: 2;
}

.rightIcon {
  justify-self: center;
  margin: 5px;
  grid-column: 3;
}

.progressBar {
  width: 100%;
  height: 40px;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}

.pointer {
  height: 40px;
  position: absolute;
  background-color: #f64e29;
  width: 5px;
}

.leftSegment {
  height: 40px;
  grid-column: 1;
}

.middleSegment {
  height: 40px;
  grid-column: 2;
}

.rightSegment {
  height: 40px;
  background-color: #0a2250;
  grid-column: 3;
}

.timeLeft {
  grid-column: 1;
  color: #0a2250;
  font-weight: bold;
  display: flex;
  flex-direction: row-reverse;
  .text {
    margin: 0;
    transform: translate(50%);
  }
}

.timeMiddle {
  grid-column: 2;
  color: #0a2250;
  font-weight: bold;
  display: flex;
  justify-content: center;
  .text {
    margin: 0;
  }
}

.timeRight {
  grid-column: 3;
  color: #0a2250;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  .text {
    transform: translate(-50%);
    margin: 0;
  }
}

.darkMode {
  .timeLeft {
    color: white;
  }

  .timeMiddle {
    color: white;
  }

  .timeRight {
    color: white;
  }

  .pointer {
    background-color: #f64e29;
  }

  .leftIcon {
    filter: invert(1) grayscale(1);
  }

  .rightIcon {
    filter: invert(1) grayscale(1);
  }
}
