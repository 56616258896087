:root {
  --background: #f0f7ff;

  --primary50: #f2f7ff;
  --primary100: #e6f0fe;
  --primary200: #d6e9fd;
  --primary300: #b7d4fc;
  --primary400: #9dc4f1;
  --primary500: #55a4f7;
  --primary550: #459cf6;
  --primary600: #3593f6;
  --primary650: #1682f4;
  --primary700: #1b6ab7;
  --primary800: #26436f;
  --primary850: #223c64; // 10% darker
  --primary900: #0d2b54;
}

:root .parent-mode {
  --background: #f3f0ff;

  --primary50: #f5f2ff;
  --primary100: #ede8ff;
  --primary200: #ded7fc;
  --primary300: #c7b7ff;
  --primary400: #ae9df2;
  --primary500: #7554f7;
  --primary550: #6845f5;
  --primary600: #5c36f5;
  --primary650: #4316f5;
  --primary700: #521bb7;
  --primary800: #352670;
  --primary850: #2f2263; // 10% darker
  --primary900: #1b0d54;

  // .altus-illustration {
  //   filter: hue-rotate(40deg);
  //   .hide-watermark {
  //     filter: hue-rotate(-40deg);
  //   }
  // }
}

$background: var(--background);
$primary: var(--primary500);

$primary50: var(--primary50);
$primary100: var(--primary100);
$primary200: var(--primary200);
$primary300: var(--primary300);
$primary400: var(--primary400);
$primary500: var(--primary500);
$primary550: var(--primary550);
$primary600: var(--primary600);
$primary650: var(--primary650);
$primary700: var(--primary700);
$primary800: var(--primary800);
$primary850: var(--primary850);
$primary900: var(--primary900);

$buttonGrey: #c1c1c1;
$buttonBlack: #2e2e2e;

$uiBlue: #55a4f7;
$uiPurple: #7555f7;
$uiGreen: #53d670;
$uiRed: #f75555;
$uiYellow: #efd16c;
$uiPink: #e9a0eb;
$uiOrange: #ff9254;

$uiPinkLight: #f1e8fe;
$uiPinkDark: #681576;
$uiBlueLight: #e6f0fe;
$uiBlueDark: #26436f;

@function myRgba($color, $alpha) {
  $inverseAlpha: 100 - $alpha;
  $color: color-mix(in srgb, $color, #0000 $inverseAlpha);
  @return $color;
}
