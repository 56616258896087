.qrCode {
  width: calc(100% + 40px);
  margin: -20px;

  > div {
    width: 100%;
  }
}

.text {
  width: 70%;
  text-align: right;
  color: #1A72C6;
}