@import '../../styles/variables.scss';

.workedChapter {
  background-color: $primary100;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  width: 100%;

  .title {
    font-weight: 500;
    font-size: 13px;
  }

  .date {
    font-size: 11px;
    color: myRgba($primary800, 90%);
    margin-top: -5px;
  }

  .progress {
    width: 100%;
    height: 10px;
    background-color: $primary300;
    border-radius: 10px;
    position: relative;

    .progressValue {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background-color: $primary;
      border-radius: 10px;
    }
  }
}
