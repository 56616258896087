@import '../../styles/variables.scss';

.fieldButton {
  display: flex;
  border: none;
  border-radius: 10px;
  height: 60px;
  align-items: center;
  gap: 20px;

  .fieldIcon {
    width: 30px;
    height: 30px;
    font-size: 20px;
    color: white;
    margin-left: 12px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
      width: 100%;
      height: 100%;
    }

    > svg {
      font-size: 30px;
    }
  }

  .fieldName {
    color: white;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    margin-right: 15px;
  }
}
