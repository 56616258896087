.cycle {
  width: 100%;
  border-radius: 14px;
  background: #C6DFFB;
  box-shadow: 0px 4px 0px 0px #A9CFF9;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1A72C6;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  padding: 10px;
  transition: all 0.3s ease-in-out;

  &.selected {
    background: #3E91E1;
    color: #fff;
  }
}
