@import '../../styles/variables.scss';

.bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: white;
  border-top: 3px solid #e5e5e5;
  height: 90px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 35px;
  flex-shrink: 0;
  z-index: 10000000;

  > * {
    padding: 10px;
    height: 50px;
    width: 50px;
    transition:
      background-color 0.2s ease-in-out,
      color 0.2s ease-in-out;
  }

  > :first-child {
    color: $primary;

    &.active {
      background-color: $primary;
    }
  }

  > :nth-child(2) {
    color: $uiOrange;
    &.active {
      background-color: $uiOrange;
    }
  }

  > :nth-child(3) {
    color: $uiBlue;
    &.active {
      background-color: $uiBlue;
    }
  }

  > :nth-child(4) {
    color: $uiGreen;
    &.active {
      background-color: $uiGreen;
    }
  }

  > :nth-child(5) {
    color: $primary800;
    &.active {
      background-color: $primary800;
    }
  }

  .active {
    color: white;
    border-radius: 5px;
  }
}
