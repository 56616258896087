@import '../../styles/variables.scss';

.wrapper {
  height: calc(100vh - 100px);
  top: 100px;
  left: 0;
  width: 100vw;
  background-color: transparent;
  position: fixed;
  z-index: 1000;

  .loseLifeWrapper {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.correction {
  background-color: lighten($uiRed, 25%);
  display: flex;
  flex-direction: column;
  color: darken($uiRed, 20%);

  .header {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      color: darken($uiRed, 20%);
      background-color: transparent;
      border: none;
      padding: 0;
      cursor: pointer;
      outline: none;
      font-size: 25px;
    }
  }

  .text {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
  }
}
