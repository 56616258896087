@import '../../styles/variables.scss';

.card {
  border-radius: 16px;
  // background-color: #e4f0ff;
  // border: 2px solid #bcdaff;
  // box-shadow: 0px 4px 0px 0px #abd1ff;
  background-color: $primary100;
  border: 2px solid $primary300;
  box-shadow: 0px 4px 0px 0px $primary400;
  overflow: hidden;
  transition:
    background-color 0.2s,
    box-shadow 0.2s,
    transform 0.2s;

  position: relative;

  &.clickable {
    &:active {
      box-shadow: 0 0 0 0 $primary400;
      transform: translateY(3px);
    }
  }

  &.blocked {
    filter: saturate(0.2);

    .offlineOverlay {
      backdrop-filter: brightness(0.8) saturate(0.4);
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;

      > img {
        width: max(32px, 50%);
        height: max(32px, 50%);
        margin: auto;
      }
    }
  }
}
