.inputPin {
  .title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: center;
    margin-top: 10px;
  }
  .input {
    width: 100%;
    padding: 10px 10px;
    font-size: 18px;
    border-radius: 10px;
    border: 2px solid #a2c4ee;
    background-color: white;
    font-weight: 500;
    text-align: center;

    &:focus {
      outline: none;
      border-color: #5f92d2;
    }

    &::placeholder {
      color: #bdbdbd;
    }
  }
}
