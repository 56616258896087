@import '../../styles/variables.scss';

.inputWrapper {
  width: 100%;
  position: relative;

  .label {
    font-size: 14px;
    font-weight: 500;
    display: block;
    margin-bottom: 5px;
    display: flex;
    gap: 4px;
  }
}

.select {
  background-color: white;
  border-radius: 20px;
  font-weight: 700;

  .editTime {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px 10px 20px;

    .time {
      font-size: 15px;
      font-weight: 700;
      color: $primary900;
    }

    .editIcon {
      background-color: transparent;
      border: none;
      font-size: 22px;
      font-weight: 700;
      color: $primary500;
      cursor: pointer;
      background-color: $primary200;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        font-size: 20px;
        width: 20px;
        height: 20px;
      }
    }
  }
}
