@import '../../styles/variables.scss';

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10000001;
  display: flex;

  .background {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  .content {
    background: white;
    width: 90%;
    margin: auto;
    padding: 16px;
    padding-top: 32px;
    border: solid 2px $primary;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
}
