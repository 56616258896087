@import '../../styles/variables.scss';

.cardLabel {
  font-weight: 600;
  margin-top: 20px;
  color: $primary800;
  text-align: center;
  font-size: 18px;
}

.instructions {
  width: 90%;
  margin: auto;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  list-style-type: decimal;
  color: $primary800;
}

.scanQrCode {
  text-align: center;
  width: 90%;
  margin: auto;
  font-size: 20px;
  font-weight: 600;
  color: $primary800;
}

.customCard {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.title {
  font-size: 18px;
  font-weight: 600;
  align-self: center;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 10px;
  width: 90%;
}

.or {
  font-size: 14px;
  font-weight: 600;
  align-self: center;
  text-align: center;
  color: $primary400;
}
